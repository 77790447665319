<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <!-- 搜索 -->
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">采集任务名称</label>
        <el-input
          v-model="search.title"
          placeholder="请输入采集任务名称"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集时间</label>
        <el-date-picker
          v-model="search.stime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="search.etime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="small"
          >查询
        </el-button>
        <el-button type="info" icon="el-icon-refresh" @click="resetSearch" size="small"
          >重置
        </el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <!-- 列表数据 -->
    <div style="flex: 1; overflow: hidden">
      <el-table
        :data="dataList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        style="width: 100%"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column
          prop="collectName"
          label="采集员姓名"
          width="260"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="failCount"
          label="审核不通过数"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 列表数据 -->
  </div>
</template>

<script>
import { auditQueryStatistics } from "@/api/appreciation";

export default {
  name: "reviewStatistics",
  data() {
    return {
      tableLoading: false,
      dataList: [],
      search: {
        title: "", //任务名称
        stime: "", //开始时间
        etime: "", //结束时间
      },
    };
  },
  methods: {
    //查询
    handleSearch() {
      this.funcAuditQueryStatistics();
    },
    //重置
    resetSearch() {
      this.search = {
        title: "", //任务名称
        stime: "", //开始时间
        etime: "", //结束时间
      };
      this.funcAuditQueryStatistics();
    },
    funcAuditQueryStatistics() {
      const load = this.$loading({
        lock: true,
        text: "数据加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.1)",
      });
      this.tableLoading = true;
      auditQueryStatistics(this.search)
        .then((resp) => {
          this.tableLoading = false;
          if (resp.code == 0) {
            this.dataList = resp.data;
          }
          load.close();
        })
        .catch(() => {
          this.tableLoading = false;
          load.close();
        });
    },
  },
  mounted() {
    this.funcAuditQueryStatistics();
  },
};
</script>
